.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;

}

.logo img {
  height: 32px;
}

.layout-head {
  position: relative;
  display: flex;
  align-items: center;
  height: 64px;
  padding: 0;
  background: #fff !important;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
}

.ant-drawer-header {
  border: 1px solid #001529 !important;
}

.trigger {
  padding: 10px 8px;
  opacity: 0.25;
  color: #fff !important;
  background: #001529
}

.trigger:hover {
  /* color: #188fff !important; */
  background: #188fff70;
  opacity: 0.5;
}

.ant-layout-header {
  padding: 0 !important;
  height: 68px !important;
}