.hospital-check {
    padding-top: 30px;
    width: 700px;
    margin: 0 auto;
}

.hospital-check .title {
    margin-bottom: 14px;
    font-family: PingFangSC-Medium;
    font-size: 18px;
    color: #333333;
    letter-spacing: 0;
    line-height: 28px;
    position: relative;
    left: -12px;
}

.hospital-check .title::before {
    background: #108CEE;
    border-radius: 1px;
    width: 4px;
    height: 12px;
    content: "";
    float: left;
    position: relative;
    top: 7px;
    margin-right: 7px;
}

.hospital-check .ant-form .ant-form-item {
    margin-bottom: 20px;
}

.hospital-check .ant-form .ant-form-item-label {
    text-align: left;
    line-height: 38px;
}

.hospital-check .ant-form .ant-form-item-label .ant-form-item-required {
    font-family: PingFangSC-Regular;
    font-size: 16px;
    color: #333333;
}

.hospital-check .ant-form .ant-input {
    background: #FBFBFC;
    border: 1px solid #F1F1F5;
    border-radius: 5px;
    padding: 4px 12px;
    font-family: PingFangSC-Regular;
    font-size: 16px;
    color: #333333;
    letter-spacing: 0;
    line-height: 28px;
}

.hospital-check .ant-form .ant-select-selector{
    background: #FBFBFC;
    border: 1px solid #F1F1F5;
    border-radius: 5px;
    padding: 3px 12px;
    font-family: PingFangSC-Regular;
    font-size: 16px;
    color: #333333;
    letter-spacing: 0;
    line-height: 28px;
    height: auto;
}

.hospital-check .ant-form .label-box {
    display: flex;
    justify-content: space-between;
}

.hospital-check .ant-form .label-input {
    width: 152px;
}

.hospital-check .ant-form textarea {
    width: 100%;
    background: #FBFBFC;
    border: 1px solid #F1F1F5;
    border-radius: 5px;
    font-family: PingFangSC-Regular;
    font-size: 16px;
    color: #A0A0A0;
    letter-spacing: 0;
    line-height: 28px;
}

.hospital-check .ant-form .ant-upload {
    width: 67px;
    height: 67px;
}

.hospital-check .ant-form .ant-upload-picture-card-wrapper {
    width: auto;
    margin-right: 20px;
}
.hospital-check .ant-form .upload-warn {
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #A0A0A0;
    line-height: 24px;
    position: absolute;
}

.hospital-check .btn-box {
    margin-top: 23px;
    text-align: right;
    margin-bottom: 30px;
}

.hospital-check .btn-box button {
    width: 108px;
    border: 1px solid #0073D3;
    border-radius: 5px;
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #0073D3;
    margin-left: 20px;
    padding: 6px 0;
    height: auto;
}

.hospital-check .btn-box .ant-btn-primary {
    background: #0073D3;
    color: white;
}

.hospital-check .ant-form .ant-input-search-button {
    background: #FFFFFF;
    border: 1px solid #F1F1F5;
    border-radius: 5px!important;
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #FF4F42;
    line-height: 20px;
    padding: 8px 17px!important;
    height: auto;
    box-shadow: 0 0;
}

.hospital-check .ant-form .add-more-btn {
    /*opacity: 0.5;*/
    border: 1px solid #0073D3;
    border-radius: 5px;
    width: 122px;
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #0073D3;
    line-height: 20px;
    text-align: center;
    padding: 9px 0;
    margin-bottom: 21px;
    margin-left: 204px;
    cursor: pointer;
}

.hospital-check .upload-text {
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #A0A0A0;
}

.hospital-check .add-more-btn .anticon-plus-circle {
    color: #0073D3;
}

.hospital-check .anticon-plus-circle {
    color: #A0A0A0;
}

.hospital-check .ant-upload-list-picture-card-container {
    height: 67px;
    width: 67px;
}