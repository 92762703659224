.main-article {
}

.main-add {
  width: 1080px;
  min-height: 80vh;
  margin-top: 24px;
}

.edit-item {
  width: 240px;
}

.edit-box {
  min-height: 480px;
  border: 1px solid #e9e9e9;
}

.txt-edit {
  height: 400px;
}

.site-form-in-drawer-wrapper {
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 100%;
  padding: 10px 16px;
  border-top: 1px solid #e9e9e9;
  background: #fff;
  text-align: right;
}

.ant-drawer-content-wrapper {
  width: 800px;
}

.preview-box {
  padding: 16px;
}

.preview-box .preview-head {
  padding: 16px 0;
  display: flex;
  justify-content: center;
  text-indent: 2em;
  font-size: 30px;
  font-weight: 700;
}

.art-mao {
  font-weight: bolder;
}

.syl-image-caption {
  display: none;
}

.ql-video {
  height: 400px;
  width: 480px;
}

.preview-content img {
  max-width: 800px;
  /*margin-left: 50px;*/
}

.disableupload{
  color: #999;
}
.ProseMirror{
  border-top: 1px solid #F1F1F5;
  padding-left: 19px;
  padding-right: 19px;
  overflow-y:auto;
  height: 400px;
  padding-top:12px;
}

.syl-placeholder{
 color:#aaa
}

.ProseMirror-focused{
  outline: none;
}
.ProseMirror video:focus{
  outline: none;
}
.ProseMirror h1{ 
  font-size: 32px;
}
.ProseMirror .syl-image-caption-input{
  display: none;
}
.syl-toolbar-button svg path:last-child {
  fill: #000;
}
.syl-toolbar-button{
 color: #000;
}