.new-video-container .content-box {
    padding-top: 20px;
    width: 1080px;
    position: relative;
}

.new-video-container .content-box .video-alert {
    position: absolute;
    color: rgb(189, 180, 178);
    width: 210px;
    left: 300px;
    top: 325px;
}

.new-video-container .content-box .button-box {
    margin-top: 20px;
    padding-left: 180px;
}

.new-video-container .content-box .button-box button {
    margin-right: 10px;
}

.new-video-container .content-box .poster-box {
    height: 102px;
    width: 102px;
    border: 1px dashed rgb(217, 217, 217);
    position: relative;
    background-color: rgb(250, 250, 250);
    border-radius: 5px;
}

.new-video-container .content-box .poster-box .upload-poster {
    max-width: 102px;
    max-height: 102px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.new-video-container .content-box .poster-box .anticon-close-circle {
    position: absolute;
    top: -8px;
    right: -8px;
    cursor: pointer;
}

.new-video-container .content-box .poster-box .loading-process {
    text-align: center;
    font-size: 14px;
    margin-top: 30px;
    margin-bottom: 5px;
}

.preview-box .xgplayer {
    max-width: 100%;
    max-height: 400px;
}

.preview-box .xgplayer video {
    max-width: 100%;
    max-height: 400px;
}

.preview-box .video-title {
    font-size: 40px;
    margin-bottom: 7px;
    font-weight: 600;
    margin-top: 20px;
}

.preview-box .video-content {
    font-size: 20px;
    margin-bottom: 10px;
}