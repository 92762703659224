.tab-pages {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 16px;
  padding-bottom: 16px;
}
.ant-message-notice-content{
padding:0px;
border-radius: 4px;
}
.ant-message-success{
  padding:10px 16px;
  background: #F0FFE9;
  border: 1px solid #85E358;
  border-radius: 4px;
}
.ant-message-error{
  padding:10px 16px;
  background: #FFF0F0 ;
  border: 1px solid #FFABAB ;
  border-radius: 4px;
}
.ant-message-warning{
  padding:10px 16px;
  background: #FFF7E6 ;
  border: 1px solid #FFDD91 ;
  border-radius: 4px;
}

.rich_media_content {
  visibility: visible !important;
}