.login-main {
  position: absolute;
  height: 100%;
  width: 100%;
  background-image: url("../../images/login-bg.png");
  background-size:100% 100%;

}

.login-main .logo{
  position: absolute;
  top: 41px;
  left: 48px;
  width: 435px;
  height: 69px;
}

.login-container{
  padding: 42px 62px 47px;
  background: #FFFFFF;
  border-radius: 8px;
  position: absolute;
  right: 160px;
  bottom: 234px;
  box-sizing: content-box;
  width: 396px;
}

.login-container .title{
  font-family: PingFangSC-Semibold;
  font-size: 24px;
  color: #333333;
  margin-bottom: 14px;
}

.login-container .ant-input-affix-wrapper{
  background: #F6F6F6;
  border-radius: 5px;
  border: 0;
  padding: 10px 14px;
}

.login-container .ant-input-affix-wrapper input{
  background-color: #f6f6f6!important;
  font-family: PingFangSC-Regular;
  font-size: 16px;
  color: #A0A0A0;
  line-height: 28px;
}

.login-container .ant-input-prefix{
  margin-right: 12px;
}

.login-container .ant-form-item:nth-child(1){
  margin-bottom: 21px;
}

.login-container .ant-form-item:nth-child(2){
  margin-bottom: 41px;
}

.login-container .ant-form-item:last-child{
  margin-bottom: 0;
}

.login-container button{
  padding: 9px 0;
  background: #108CEE;
  border-radius: 5px;
  font-family: PingFangSC-Medium;
  font-size: 18px;
  color: #FFFFFF;
  letter-spacing: 1px;
  line-height: 28px;
  height: auto;
}

/*.login-container */
